@import "../../variable.scss";

.Jobs {
    min-height: 100vh;
    font-size: $font_size;
    background-image: linear-gradient(to top right,
            rgba(white, 0.9),
            rgba(white, 0.8)),
        url("../../assets/images/App-background2.png");

    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 100vw;
    background-attachment: fixed;

    h1 {
        text-align: center;
    }

    b {
        color: $alpha_lightgray;
    }

    p {
        margin-top: 10px;
    }

    .header-image {
        display: block;

        width: 100vw;
        height: 300px;
        background-image:
            url("../../assets/images/gallery11.jpg");


        background-position: cover;
        background-repeat: no-repeat;
        background-size: 100vw;
    }

    .Jobs-container {
        padding: 10% 25%;

        &-item {
            padding: 5rem 0;

            &:hover {
                background-color: rgba(white, 0.3);
            }
        }
    }

}


@media (max-width: 56.25em) {
    .Jobs {
        background-size: 100vh;

        .Jobs-container {
            padding: 10% 5%;

            &-item {
                padding: 20px 0;
            }
        }
    }
}