* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  html,
  body {
    font-family: Inter;
    text-rendering: optimizeLegibility;
    // font-weight: 300;
    height: 100%;
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
    color: #262626;
    letter-spacing: 0.3px;
  }
  
  .pos-rel {
    position: relative !important;
  }
  
  .pos-abs {
    position: absolute !important;
  }
  
  .pos-fix {
    position: fixed !important;
  }
  
  .flex {
    display: flex;
  }
  
  .flex-row {
    flex-flow: row;
  }
  
  .flex-col {
    flex-flow: column;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .flex-1 {
    flex: 1;
  }
  
  .flex-70 {
    flex: 70%;
  }
  .just-start {
    justify-content: flex-start;
  }
  
  .just-center {
    justify-content: center;
  }
  
  .just-end {
    justify-content: flex-end;
  }
  
  .just-between {
    justify-content: space-between;
  }
  .just-around {
    justify-content: space-around;
  }
  
  .items-start {
    align-items: flex-start;
  }
  
  .items-center {
    align-items: center;
  }
  
  .items-end {
    align-items: flex-end;
  }
  
  .item-stretch {
    align-items: stretch;
  }
  
  .content-stretch {
    align-content: stretch;
  }
  
  .block {
    display: block;
  }
  
  .inline-block {
    display: inline-block;
  }
  
  .center-text {
    text-align: center;
  }
  
  .right-text {
    text-align: right;
  }
  
  .left-text {
    text-align: left;
  }
  
  .no-decor {
    text-decoration: none;
    color: #000000;
  }
  
  .no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  
  .pointer {
    cursor: pointer;
  }
  
  .width-100 {
    width: 100%;
  }
  
//   h1,
//   h2,
//   h3 {
//     font-family: Oswald, sans-serif;
//     letter-spacing: 2px;
//   }
  
//   a {
//     text-decoration: none;
//     color: darkslateblue;
//   }
  
  // Media queries
//   @media (max-width: 600px) {
//     html,
//     body {
//       font-size: 80%;
//     }
//   }
  