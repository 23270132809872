// COLORS
$alpha_blue: #a5c1cc;
$alpha_light_blue: #bdd9e4;
$alpha_green: #8c9d59;
$alpha_gray: #343434;
$alpha_lightgray: #565656;
$alpha_white: white;

$font_size: calc(6px + 2vmin);
$font_size_zoom: calc(8px + 2vmin);
$font_header: calc(20px + 2vmin);
$font_medium: calc(15px + 2vmin);
$font_small: calc(3px + 2vmin);
$font_subtext: calc(2px + 2vmin);

$svg_white: invert(99%) sepia(1%) saturate(2%) hue-rotate(124deg) brightness(110%) contrast(101%);
$svg_black: invert(0%) sepia(100%) saturate(2%) hue-rotate(196deg) brightness(101%) contrast(100%);
$svg_darkgray: invert(18%) sepia(0%) saturate(1495%) hue-rotate(147deg) brightness(95%) contrast(90%);