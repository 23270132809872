@import "../../variable.scss";

.Home:before {
    content: "";
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    z-index: -10;
    background:
        url(../../assets/images/App-background.png) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.Home {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: $font_size;
    overflow-x: hidden;

    .Alpha-title {
        text-align: center;
    }


    /** ---------- START OF HEADER ---------- **/
    .Home-header {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: -5px;

        background-image: url("../../assets/images/headerimg.jpg");
        background-position: center;
        /* Center the image */
        background-repeat: no-repeat;
        /* Do not repeat the image */
        background-size: cover;
        /* Resize the background image to cover the entire container */

        &-container {
            flex-flow: row;
        }

        .AlphaLogo-img {
            width: 66vmin;
            margin-left: -27%;
        }

        .Announcement-container {
            background-color: rgba(255, 255, 255, 0.5);
            padding: 3vw;
            border-radius: 10px;
        }

    }
}

/** ---------- START OF DETAILS ---------- **/
.Alpha-Details {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 5% 0;

    h3 {
        margin: 4vmin;
    }

    .Alpha-Details-item {
        padding: 0 5vmin;
    }

}

/** ---------- START OF SPEISEN ---------- **/
.Alpha-Speisen {
    background-color: transparent;
    min-height: 50vmin;
    color: $alpha_white;
    position: relative;

    b {
        font-size: $font_size_zoom;
        letter-spacing: 1px;
    }


    .Speisen-img {
        width: 36vmax;
        border-radius: 10px;
        box-shadow: -5px 5px white;
    }

    .Speisen-label {
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-top: 20px;
        font-size: calc(10px + 2vmin);
        transform: rotate(-5deg);
    }

    .Speisen-button {
        width: 18vh;
        position: absolute;
        transform: rotate(-13deg);
        right: -18px;
        top: 40px;
    }

    .Speisen-arrow {
        width: 15vh;
        position: absolute;
        transform: rotate(-13deg);
    }


    &-Right {
        height: 100%;
        width: 100%;
        padding: 2rem;
        text-align: center;
    }

    &-content {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        justify-content: space-around;
        padding-top: 15vmin;
        padding-bottom: 5vmin;
    }

    &-button-container {
        position: relative;
        height: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
    }

}


/** ---------- START OF GALLERY ---------- **/
.Alpha-Gallery {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    h3 {
        margin: 20px;
    }

    &-grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    &-grid-item {
        // flex: 1;
        background-color: yellow;
        cursor: pointer;
        height: 20vmax;
        margin: 5px;
        overflow: hidden;
    }

    &-grid-img {
        height: 100%;
        width: auto;
    }
}

/** ---------- START OF CONTACT ---------- **/

.Alpha-Contact {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5vh;


    &-Left {
        // background-color: $alpha_white;
        text-align: left;
        width: 100%;

        .Alpha-address {
            margin: 5vh 0;
        }

        .Alpha-map {
            margin: 10vh 0;
        }

        a {
            color: $alpha_gray;
            font-family: Inter;
            font-size: $font_size;
            font-style: italic;
        }
    }

    &-Right {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: 10vh 0;
        color: $alpha_gray;

    }

    .Alpha-logo {
        max-width: 28vmax;
        height: auto;
    }

    .Logos-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .Logos-item {
        width: 15vmin;
        height: auto;
        opacity: 0.9;
        filter: brightness(0);
    }

}

.Up-Border {
    // position: absolute;
    // top: 0;
}

.Bottom-Border {
    margin-bottom: -5px;
}

.Border-img {
    width: 100vw;
}

/**  START OF MEDIA QUERIES **/
// Tablet
@media (max-width: 62.25em) {

    .Home {
        .Home-header {
            flex-flow: column;
            justify-content: center;
            background-image: url("../../assets/images/headerimgmobile.JPG");
            height: 70vh;

            .AlphaLogo-img {
                margin-left: 0;
                width: 75vmin;
            }

            &-container {
                flex-flow: column;
            }
        }

    }

    .Alpha-Details {
        flex-flow: column;
        justify-content: space-around;
        min-height: 70vh;

        .Details-button-containter {
            max-width: 15vmin;
            max-height: 10vmin;
        }
    }

    .Alpha-Speisen {
        height: 80vmax;

        &-content {
            flex-flow: column;
            justify-content: flex-start;
            align-items: center;
            padding-top: 8vmax;
            height: 100%;
        }

        .Speisen-label {
            margin-top: 30px;
        }

    }

    .Alpha-Gallery {
        padding: 4vh 0;

        &-grid-item {

            height: 10vmax;
        }
    }

    .Alpha-Contact {
        flex-flow: column-reverse;
        padding: 0;
        background-color: transparent !important;

        &-Right {

            color: $alpha_white;
            background: url("../../assets/images/background2.JPG") no-repeat center center;
            background-size: 100vw;
            // background-attachment: fixed;
            width: 100%;
            height: 100%;
            background-blend-mode: difference;
            background-position-y: center;
        }

        &-Left {
            padding: 10vw 5vw;
            background-color: transparent !important;
        }

        .Logos-item {

            filter: unset;
        }

        .Button {
            border: 1px $alpha_white solid;
        }

        .Right-arrow-img {

            .Right-arrow-img {
                width: 3vmax;
                filter: unset;
            }
        }
    }
}


// Mobile 
@media (max-width: 37.5rem) {

    .Alpha-Speisen {
        .Speisen-label {
            margin-top: 30px;
        }

    }
}