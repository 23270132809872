@import "../../variable.scss";

.Navigation {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    padding: 1em 25%;
    width: 100vw;
    position: absolute;
    top: 0;
    z-index: 10;

    background-color: $alpha_gray;
    color: $alpha_white;
}

.Modal {
    position: fixed;
    z-index: 5;

    // position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .Modal-content {
        width: fit-content;
        position: relative;
    }

    img {
        width: 85%;
        height: auto;
        max-height: 80vh;
    }

    .close {
        position: absolute;
        top: -15px;
        right: 5%;
        width: 10vmin;
        height: auto;
        cursor: pointer;
    }
}

.Footer {
    background-color: $alpha_gray;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    height: 10vh;
    width: 100vw;

    b,
    a {
        color: $alpha_white;
        margin: 0 10px;
    }

}

.static-container {
    padding: 5em 10em;
    position: relative;
    background-color: white;
    width: 100%;
    min-height: 100vh;

    // width: 20%;
    p {
        display: contents;
    }

    h1 {
        font-size: $font_header;
        text-transform: uppercase;
    }

    h2 {
        font-size: $font_medium;
    }

    p,
    b,
    h3 {
        font-size: $font_size_zoom
    }

    .paragraph {
        margin-left: 3em;
    }

    .daten-body {
        display: block;
    }
}



@media (max-width: 56.25em) {
    .static-container {
        padding: 10vmin 5vmin;
        margin: 0;
        display: flex;
        flex-flow: column;
    }

    .Navigation {
        display: none;
    }
}

.hidden {
    display: none;
}