// #BDD9E4
@import "./variable.scss";



@font-face {
    font-family: "Oswald";
    src: local("Oswald"),
        url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    src: local("Inter"),
        url("./assets/fonts/Inter-ExtraLight.ttf") format("truetype");
}

h1,
h2,
h3,
h4 {
    font-family: Oswald;
    text-transform: uppercase;
    font-weight: 400;
}

h1 {
    font-size: $font_header;
}

a {
    color: #BDD9E4;
    font-family: Oswald;
    text-decoration: none;
    font-size: $font_size;
}

b {
    font-size: $font_size;
    font-family: Oswald;
    font-weight: 400;
}



.App {
    overflow-x: hidden;
}

.modal-mode {
    opacity: 0.3;
}

.Button {
    padding: 12px 46px;
    margin: 15px 0;
    border-radius: 10px;
    border: 1px $alpha_gray solid;
    box-shadow: -5px 5px #BDD9E4;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    cursor: pointer;

    .Right-arrow-img {
        width: 2vmax;
        margin: 0 10px;
        opacity: 0.9;
        filter: brightness(0);
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        /* animation: App-logo-spin 1 20s ease-in; */

        animation-name: Animation-floating;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        margin-left: 30px;
        margin-top: 5px;

    }
}

/** ----------  END OF HEADER ---------- **/

.App-section-fullscreen {
    min-height: 100vh;
    background-color: blue;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
}

.App-section-small {
    min-height: 60vh;
    background-color: white;
    position: relative;
    // justify-content: space-between;
    // flex-flow: column;
    // display: flex;

}

.App-section-small-transparent {
    min-height: 60vh;
    position: relative;
}

.content-container {
    padding: 5% 10%;
}

.content-container-small {
    padding: 5% 5%;

}

.Details-button-containter {
    min-width: 29vmin;
    min-height: 35vmin;
    border: 1px black solid;
    border-radius: 10px;
    margin: 25px 10px;
    padding: 3vmin;
    font-size: calc(6px + 2vmin);
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    box-shadow: -5px 5px #BDD9E4;

    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    .Button-icon {
        width: 15vmin;
    }

    .Button-label {
        color: $alpha_gray;
    }

}

.Details-button-containter-small {
    min-width: 16vmin;
    max-width: 16vmin;
    min-height: 20vmin;
    max-height: 22vmin;
    background-color: rgba(255, 255, 255, 0.5);
}

/** ---------- ANIMATIONS ---------- **/
@keyframes Animation-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes Animation-floating {
    0% {
        transform: translate(0, -15px);
    }

    /* 50%  { transform: translate(0, 15px); } */
    100% {
        transform: translate(0, 0px);
    }
}



/**  START OF MEDIA QUERIES **/
@media (max-width: 56.25em) {
    .App {

        .content-container,
        .content-container-small {
            padding: 4% 3%;
        }

    }

    .Button {
        padding: 8px;
    }
}