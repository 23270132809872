@import "../../variable.scss";

#menu {
    // font-size: 3em;
    position: relative;

    background-image: linear-gradient(to top right,
            rgba(white, 0.9),
            rgba(white, 0.8)),
        url("../../assets/images/Menu-background.JPG");

    background-position: start;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 100vw;
    background-attachment: fixed;

    .button {
        // background-color: white;
        padding: 1rem;
        width: 30rem;
        // border-radius: 10px;

        a {
            color: $alpha_gray
        }
    }

    .menu-section-buttons {
        position: fixed;
        top: 8%;
        left: 1em;
        display: flex;
        flex-flow: column;
        text-decoration: none;
        // background-color: $alpha_blue;
        // border-top-left-radius: 10px;
        // border-top-right-radius: 10px;

        .menu-section-button {
            // text-align: center;
            align-items: center;
            justify-content: center;
            // background-color: rgba(255, 255, 255, 0.5);
            padding: 0.1em 0.7em;
            // border-radius: 35px;
            margin: 10px;
            cursor: pointer;
            text-decoration: none;
            color: $alpha_gray;
            -webkit-transition: all .3s ease;
            -moz-transition: all .3s ease;
            transition: all .3s ease;

            // &:hover {
            //     color: $alpha_blue;
            //     transform: scale(1.1);
            // }

            &-active {
                font-size: $font_medium;
                padding: 0.6em 0.7em;
                color: $alpha_blue;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                transition: all .3s ease;
            }
        }
    }

    .menu-body {
        padding: 1em 25%;


        .Menu-main-title {
            font-family: Inter;
            letter-spacing: 2px;
            text-align: center;
            margin-top: 5vh;
        }

        .menu-items {
            font-size: $font_size;

            .menu-section {
                .menu-title {
                    text-align: center;
                    margin-top: 4em;

                    h3 {
                        margin: 10px 0;
                    }

                    .line {
                        height: 5px;
                        background-color: $alpha_blue;
                    }

                    .description {
                        font-style: italic;
                        font-size: $font_small;
                        margin: 1em 0 2em;
                    }
                }

                .menu-item {
                    // margin: 0.5em 0;
                    color: $alpha_lightgray;
                    padding: 10px;

                    &:hover {
                        background-color: rgba(white, 0.3);
                    }

                    .vegan-icon {
                        height: 1.2em;
                    }

                    .sub-text {
                        font-size: $font_size;
                        width: 85%;
                    }

                    &-title {
                        font-size: $font_size_zoom;
                    }

                    .right-content {
                        width: 20%;
                    }

                    .left-content {
                        width: 80%;
                    }
                }
            }
        }
    }
}

@media (max-width: 37.5rem) {
    #menu {
        // font-size: 1.3em;
    }
}

@media (max-width: 56.25em) {
    #menu:before {

        content: "";
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        z-index: -10;
        background:
            linear-gradient(to top right,
                rgba(white, 0.9),
                rgba(white, 0.8)),
            url("../../assets/images/menu-background-mobile.jpg") no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    #menu {
        display: flex;
        flex-flow: column;
        scroll-margin-top: 10em;
        background-image: unset;

        .button {
            width: 100%;
        }

        .menu-section-buttons {
            top: unset;
            flex-flow: row;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            width: 100%;
            left: 0;
            top: 0;
            padding: 10px 0;
            background-color: white;
            letter-spacing: 1px;
            box-shadow: 0px 0px 4px 3px #dcdcdc;

            .menu-section-button {
                margin: 10px 5px;

                &-active {
                    font-size: $font_size ;
                    padding: 0;
                    color: $alpha_blue;
                }
            }

            .invisible {
                width: 100px;
            }


        }

        .menu-body {
            padding: 22% 1em;
            width: 100vw;
            scroll-padding-top: 4em;

            .Menu-main-title {
                margin-top: 1vh;
            }

            .menu-section {
                margin: 0;
                padding-top: 11em;
                margin-top: -12em;

                // #menu .menu-body .menu-items .menu-section .menu-item .right-content
                .menu-item {
                    .right-content {
                        width: 40% !important;
                    }

                    .left-content {
                        width: 60% !important;
                    }
                }
            }
        }
    }
}

@keyframes mymove {
    100% {
        font-size: $font_medium;
    }
}